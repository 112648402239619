<template>
  <div id="forgetpassword">
    <Header></Header>
    <div class="content-wrapper">
      <div class="breadcrumb-wrap bg-f br-1">
        <div class="overlay bg-black op-9"></div>
        <img :src="shape1" alt="Image" class="br-shape-1" />
        <img :src="shape2" alt="Image" class="br-shape-2" />
        <div class="container">
          <div class="row">
            <div class="col-xl-12">
              <div class="breadcrumb-title">
                <h2>Change Password</h2>
                <ul class="breadcrumb-menu list-style">
                  <!--<li><a href="index-2.html">Home </a></li>-->
                  <router-link to="/" style="color: white"
                    >Home &nbsp;&rarr;</router-link
                  >
                  <li>Change Password</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section class="login-wrap pt-100 pb-100">
        <img :src="shape1" alt="Image" class="login-shape-1" />
        <img :src="shape2" alt="Image" class="login-shape-2" />
        <div class="container">
          <div class="row">
            <div
              class="col-xl-6 offset-xl-3 col-lg-8 offset-lg-2 col-md-8 offset-md-2"
            >
              <div class="login-form">
                <div class="login-header bg-green">
                  <h2 class="text-center mb-0">Change Password</h2>
                </div>
                <div class="login-body">
                  <form
                    class="form-wrap"
                    action="#"
                    @submit.prevent="changePassword"
                  >
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="form-group">
                          <label for="email">E-mail</label>
                          <input
                            id="email"
                            name="email"
                            v-model="form.email"
                            type="email"
                            placeholder="Email Address*"
                            required
                          />
                        </div>
                      </div>
                      <div class="col-lg-12">
                        <div class="form-group">
                          <label for="email">Code</label>
                          <input
                            name="code"
                            v-model="form.code"
                            type="text"
                            placeholder="Code*"
                            required
                          />
                        </div>
                        <span class="text-danger" v-if="error.code">{{
                          error.code
                        }}</span>
                      </div>
                      <div class="col-lg-12">
                        <div class="form-group">
                          <label for="password">Password</label>
                          <input
                            name="password"
                            v-model="form.password"
                            type="password"
                            placeholder="Password*"
                            required
                          />
                        </div>
                        <span class="text-danger" v-if="error.password">{{
                          error.password
                        }}</span>
                      </div>
                      <div class="col-lg-12">
                        <div class="form-group mb-0">
                          <button
                            class="btn style1 w-100 d-block"
                            type="submit"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Header from "@/views/layouts/Header.vue";
import Footer from "@/views/layouts/Footer.vue";
import { changeUserPassword } from "@/store/api";
//import Mainslider from './layouts/Mainslider.vue';
export default {
  name: "forgetpassword",
  data() {
    return {
      shape1: require("@/assets/img/shape-1.png"),
      shape2: require("@/assets/img/shape-2.png"),
      form: {
        email: "",
        code: "",
        password: "",
      },
      error: {
        email: "",
        code: "",
        password: "",
      },
    };
  },
  mounted() {
    this.form.email = this.$route.query.email;
  },
  methods: {
    changePassword() {
      this.error.code = "";
      this.error.password = "";
      if (this.form.password.length < 8) {
        this.error.password = "Password contains atleast 8 characters";
      } else if (!this.form.code) {
        this.error.code = "Enter reset code";
      } else {
        changeUserPassword(this.form).then((res) => {
          console.log(res.data);
          if (
            res.data.success === false &&
            res.data.message === "Invalid OTP"
          ) {
            this.error.code = "Invalid OTP";
          } else if (res.data.success === true) {
            this.$toast.success("Password changed successfully");
            this.$router.push("/login");
          }
        });
      }
    },
  },
  components: {
    Header,
    Footer,
  },
};
</script>
